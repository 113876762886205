export const environment = {
    production: false,
    brandCode: 'yuzuan',
    env: 'dev',
    firebase: {
        apiKey: 'AIzaSyDdADeB_CITBbWFdjt1YBGQnjZ11w-x5dU',
        authDomain: 'mc-app-yuzu-dev.firebaseapp.com',
        projectId: 'mc-app-yuzu-dev',
        storageBucket: 'mc-app-yuzu-dev.appspot.com',
        messagingSenderId: '606812004785',
        appId: '1:606812004785:web:703c17ca9668880b741d90',
        measurementId: 'G-87SZWRM713',
    },
    hostingBaseURL: 'https://app-dev.shabu-yuzuan.jp',
    appScheme: 'yuzuan-monogatari-app-dev',
    storeURL: {
        ios: 'https://apps.apple.com/app/id1499042286',
        android: 'https://play.google.com/store/apps/details?id=jp.shabu_yuzuan.members',
    },
}
